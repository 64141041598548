export const {
  WEB_VERSION = '0.0.0',
  IOS_VERSION = '0.0.0',
  ANDROID_VERSION = '0.0.0',
  REACT_APP_API_KEY: API_KEY = '',
  REACT_APP_PROJECT_ID: PROJECT_ID = '',
  REACT_APP_APP_ID: APP_ID = '',
  REACT_APP_MEASUREMENT_ID: MEASUREMENT_ID = '',
  REACT_APP_DATABASE_URL: DATABASE_URL = '',
  REACT_APP_STRIPE_PUBLIC_KEY: STRIPE_PUBLIC_KEY = '',
  REACT_APP_US_PAYIX_IFRAME_PATH: US_PAYIX_IFRAME_PATH = '',
  REACT_APP_CANADA_PAYIX_IFRAME_PATH: CANADA_PAYIX_IFRAME_PATH = '',
  REACT_APP_SENTRY_DSN: SENTRY_DSN = '',
  REACT_APP_SENTRY_PROJECT_ID: SENTRY_PROJECT_ID = '',
  REACT_APP_INTERMEDIARY_API_PATH: INTERMEDIARY_API_PATH = '',
  REACT_APP_SCRATCHPAY_URL: SCRATCHPAY_URL = '',
  REACT_APP_SCRATCHPAY_FAQS_URL: SCRATCHPAY_FAQS_URL = 'https://scratchplan.stonly.com/kb/en',
  REACT_APP_SCRATCHPAY_SUPPORT_PHONE_NUM: SCRATCHPAY_SUPPORT_PHONE_NUM = '+18557272395',
  REACT_APP_SCRATCHPAY_SUPPORT_EMAIL: SCRATCHPAY_SUPPORT_EMAIL = 'support@scratchpay.com',
  REACT_APP_ENABLE_MAVEN_WIDGET: ENABLE_MAVEN_WIDGET = 'false',
  REACT_APP_MAVEN_SCRIPT_URL: MAVEN_SCRIPT_URL = 'https://chat-v2.onmaven.app/js/widget.js',
  REACT_APP_OSANO_SCRIPT_URL:
    OSANO_SCRIPT_URL = 'https://cmp.osano.com/16CLbrT4hDKpjDYuF/42f15ba9-04ea-4cee-84d5-117d310f313e/osano.js',
} = process.env;

export const CURRENCY_FORMAT = '0,0.00'; // the format followed by numeral package
export const DEFAULT_CURRENCY_SYMBOL = '$';
export const DEFAULT_CURRENCY_UNIT = 100;

export const AREA_CODE_US_CANADA = '+1';

export const DEFAULT_VALUE_MISSING_FIELDS = 'N/A';
export const DEFAULT_VALUE_UNAVAILABLE_FEE = '?';
export const DEFAULT_VALUE_PENDING_LOAN_FIELDS = '...';
export const CANNOT_FETCH_DATA_ERROR_MESSAGE = "Can't fetch data";

export const PAYMENT_METHOD_TYPES: { [field: string]: string } = {
  CARD: 'CARD',
  ACH: 'ACH',
  PAD: 'PAD',
};

export const PAYMENT_METHOD_VENDORS = {
  STRIPE: 'stripe',
  PAYIX: 'payix',
};

export const CHOSEN_PAYMENT_OPTIONS = {
  TAKE_FIVE: 'take_five',
  POT: 'pay_over_time',
};

export const SUPPORT_CONTACT = {
  PHONE_NUMBER_FORMATTED: '+1 (855) 727-2395',
  PHONE_NUMBER: '8557272395',
  FAX_NUMBER: '2134026975',
  EMAIL: 'support@scratchpay.com',
  ADDRESS: '225 S Lake Ave Suite #250, Pasadena, CA 91101, United States',
};

export const isCordovaApp = !!window.cordova;
export const isCordovaAndroidApp = !!window.cordova && window.cordova.platformId.toLowerCase() === 'android';
export const isCordovaIOSApp = !!window.cordova && window.cordova.platformId.toLowerCase() === 'ios';
const cordovaVersion = !isCordovaAndroidApp ? IOS_VERSION : ANDROID_VERSION;
export const appVersion = !isCordovaApp ? WEB_VERSION : cordovaVersion;
export const DEVICE_CLASS = isCordovaApp ? 'mobile' : 'web';

export const AUTH_METHOD_SMS = 'sms';
export const AUTH_METHOD_VOICE_CALL = 'voice_call';

export const CLIENT_STORAGE_KEY = {
  SELECTED_LOAN_ID: 'selectedLoanId',
  CUSTOM_PAYMENT_AMOUNT: 'customPaymentAmount',
} as const;

export const INAPPBROWSER_SETTINGS = {
  OPEN_IN_SYSTEM_BROWSER: '_system',
  SHOW_LOCATION_BAR: 'location=yes',
};

export const MAVEN_AGI = {
  WIDGET_TITLE: 'Get Help',
  CSS_CLASS: 'contains-maven-widget',
  CSS_SELECTOR: '#maven-chat-widget',
  LABEL_CSS_SELECTOR: '#maven-chat-widget > div > div > span',
};

export const OSANO = {
  CSS_SELECTOR: '.osano-cm-window__widget',
};
