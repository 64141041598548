import { OSANO_SCRIPT_URL, OSANO } from './constants.ts';
import { routes } from './routeHelper.ts';

function getOsanoWidget(): Element | null {
  const osanoWidget = document.querySelector(OSANO.CSS_SELECTOR);

  return osanoWidget;
}

export function removeOsanoWidget() {
  const osanoWidget = getOsanoWidget();

  if (osanoWidget) {
    osanoWidget.remove();
  }
}

export function addOsanoWidget(pathname: string) {
  const osanoWidget = getOsanoWidget();

  if (osanoWidget) {
    return;
  }

  const allowedPaths = [routes.LOGIN];
  const isAllowedPath = allowedPaths.includes(pathname);

  if (!isAllowedPath) {
    return;
  }

  const script = document.createElement('script');
  script.src = OSANO_SCRIPT_URL;

  document.body.appendChild(script);

  return () => {
    removeOsanoWidget();

    document.body.removeChild(script);
  };
}
