import { ReactNode } from 'react';
import { pick } from 'lodash';
import { Grid } from '@mui/material';
import { GridSize } from '@mui/material/Grid';

import './Col.scss';

interface IContainerProps {
  stylename?: string;
  className?: string;
}

interface IItemProps {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
}

type Props = { children: ReactNode } & IContainerProps & IItemProps;

const Col = (props: Props) => {
  const containerProps: IContainerProps = pick(props, ['className', 'stylename']);
  const itemProps: IItemProps = pick(props, ['xs', 'sm', 'md']);

  return (
    <div styleName="wrapper">
      <Grid container justifyContent="center" {...containerProps}>
        <Grid item styleName="wrapper-item col" {...itemProps}>
          {props.children}
        </Grid>
      </Grid>
    </div>
  );
};
export default Col;
