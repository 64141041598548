import { ChangeEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { Fab, Grid } from '@mui/material';

import './Fab.scss';

export const FAB_COLORS: Record<string, 'green'> = {
  GREEN: 'green',
};

interface IProps {
  children: any;
  disabled?: boolean;
  width?: number | string;
  bottom?: number;
  type?: 'submit' | 'button';
  onClick?: (event: ChangeEvent<{}>) => void;
  autoResponsive?: boolean;
  AboveComponent?: ReactNode;
}

const CustomFab = (props: IProps) => {
  const {
    children,
    bottom,
    onClick,
    width,
    disabled = false,
    type = 'button',
    autoResponsive = true,
    AboveComponent,
  } = props;

  const style = {
    width,
    padding: 0,
    ...(bottom && { bottom }),
  };

  const responsiveProps: { [key: string]: any } = autoResponsive ? { xs: 9, sm: 6, md: 4 } : {};

  return (
    <Grid container styleName="wrapper" className="responsive-grid-wrapper">
      {AboveComponent}
      <Grid item {...responsiveProps} className="responsive-grid-item-wrapper">
        <Fab
          type={type}
          variant="extended"
          style={style}
          styleName={classNames('fab')}
          disabled={disabled}
          onClick={onClick}
        >
          {typeof children === 'string' ? <span>{children}</span> : children}
        </Fab>
      </Grid>
    </Grid>
  );
};

export default CustomFab;
