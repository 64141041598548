import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ENABLE_MAVEN_WIDGET, MAVEN_AGI, MAVEN_SCRIPT_URL } from './constants.ts';
import { routes } from './routeHelper.ts';

let OPENED = false;

function getMavenWidget(): Element | null {
  const widget = document.querySelector(MAVEN_AGI.CSS_SELECTOR);

  return widget;
}

export function removeLabel() {
  const label = document.querySelector(MAVEN_AGI.LABEL_CSS_SELECTOR);

  label?.remove();
}

function closeMavenWidget() {
  window?.Maven?.ChatWidget?.close();

  OPENED = false;
}

function openMavenWidget() {
  window?.Maven?.ChatWidget?.open();

  OPENED = true;
}

export function removeMavenWidget() {
  const mavenWidget = getMavenWidget();

  if (mavenWidget) {
    mavenWidget.remove();
    document.body.classList.remove(MAVEN_AGI.CSS_CLASS);
  }
}

function addMavenWidget({ useShortVersionOnMobile }: { useShortVersionOnMobile: boolean }) {
  const isMobile = window.innerWidth <= 768;
  document.body.classList.add(MAVEN_AGI.CSS_CLASS);

  const script = document.createElement('script');
  script.src = MAVEN_SCRIPT_URL;
  script.defer = true;

  document.body.appendChild(script);

  script.onload = () => {
    if (window.Maven && window.Maven.ChatWidget) {
      window.Maven.ChatWidget.load({
        orgFriendlyId: 'scratchpay',
        agentFriendlyId: 'borrowers',
        textColor: 'white',
        bgColor: '#5B70FF',
      });

      const mavenWidget = getMavenWidget();

      if (useShortVersionOnMobile && isMobile) {
        removeLabel();
      }

      (mavenWidget as unknown as Element)?.addEventListener('click', () => {
        if (OPENED) {
          closeMavenWidget();

          if (useShortVersionOnMobile && isMobile) {
            removeLabel();
          }
        } else {
          openMavenWidget();
        }
      });
    }
  };
}

export function useMavenWidget(): void {
  const location = useLocation();

  useEffect(() => {
    const existingMavenWidget = getMavenWidget();

    if (existingMavenWidget) {
      removeMavenWidget();
    }

    const allowedPaths = {
      [routes.CONTACT]: { useShortVersionOnMobile: false },
      [routes.HOME]: { useShortVersionOnMobile: false },
      [routes.MY_SCHEDULED_PAYMENTS]: { useShortVersionOnMobile: false },
      [routes.MY_PAYMENT_METHODS]: { useShortVersionOnMobile: true },
      [routes.MY_PAYMENTS]: { useShortVersionOnMobile: true },
      [routes.MY_LOANS]: { useShortVersionOnMobile: true },
      [routes.MY_DOCUMENTS]: { useShortVersionOnMobile: false },
    };
    const path = allowedPaths[location.pathname];

    if (!path) {
      return;
    }

    addMavenWidget(path);
  }, [location.pathname]);

  if (!ENABLE_MAVEN_WIDGET) {
    return;
  }
}
