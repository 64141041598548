import { ToastContainer, Slide } from 'react-toastify';

import {
  useAuthenticationSubscriber,
  useBorrowerSubscriber,
  useLoanAndScheduledPaymentSubscriber,
  usePaymentFetcher,
  usePaymentMethodFetcher,
} from 'hooks';
import RouterContainer from 'routers/RouterContainer';
import { useMavenWidget } from 'utils/maven';

import ScrollToTop from 'components/widgets/ScrollToTop';
import { InternalServerErrorDialog } from 'components/widgets';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import useDocumentsFetcher from 'hooks/useDocumentsFetcher';

export const App = () => {
  useBorrowerSubscriber();
  useLoanAndScheduledPaymentSubscriber();
  usePaymentFetcher();
  usePaymentMethodFetcher();
  useDocumentsFetcher();
  useMavenWidget();
  const currentUser = useAuthenticationSubscriber();
  if (!currentUser.isChecked) {
    return null;
  }

  return (
    <>
      <ScrollToTop />
      <RouterContainer />
      <ToastContainer
        position="top-center"
        closeButton={false}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        autoClose={3000}
        hideProgressBar
        draggablePercent={40}
        limit={2}
        role="alert"
        transition={Slide}
        toastClassName="toast"
      />
      <InternalServerErrorDialog />
    </>
  );
};

export default App;
