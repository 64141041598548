import {
  IconACH,
  IconCardVisa,
  IconCardMastercard,
  IconCardAmericanExpress,
  IconCardJCB,
  IconCardUnionPay,
  IconCardDiscover,
  IconCardDinersClub,
} from 'assets/icons';

import './PaymentMethodLogo.scss';

export const CARD_BRAND = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  JCB: 'jcb',
  AMEX: 'amex',
  DISCOVER: 'discover',
  DINERS: 'diners',
  DINERS_CLUB: 'diners club',
  UNIONPAY: 'unionpay',
};

interface IProps {
  isCard: boolean;
  cardBrand?: string;
  variant?: 'small' | 'medium' | 'large';
}

const PaymentMethodLogo = ({ isCard, cardBrand = '', variant = 'large' }: IProps) => {
  const baseStyleName = `icon icon-${variant}`;
  if (!isCard) {
    return <IconACH styleName={baseStyleName} />;
  }

  switch (cardBrand.toLowerCase()) {
    case CARD_BRAND.VISA:
      return <IconCardVisa styleName={baseStyleName} />;
    case CARD_BRAND.MASTERCARD:
      return <IconCardMastercard styleName={baseStyleName} />;
    case CARD_BRAND.JCB:
      return <IconCardJCB styleName={`${baseStyleName} jcb`} />;
    case CARD_BRAND.AMEX:
      return <IconCardAmericanExpress styleName={baseStyleName} />;
    case CARD_BRAND.DISCOVER:
      return <IconCardDiscover styleName={baseStyleName} />;
    case CARD_BRAND.DINERS:
    case CARD_BRAND.DINERS_CLUB:
      return <IconCardDinersClub styleName={baseStyleName} />;
    case CARD_BRAND.UNIONPAY:
      return <IconCardUnionPay styleName={baseStyleName} />;
    default:
      return null;
  }
};

export default PaymentMethodLogo;
