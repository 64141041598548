import { useSelector } from 'react-redux';

import { List, ListItem } from '@mui/material';

import { useLoanAndScheduledPaymentFetcher, useApplicationUrlCreator } from 'hooks';
import { setSelectedLoanId } from 'actions/loan';
import { loansSelector, isFetchingOrSynchingSelector } from 'selectors/loan';
import { routes } from 'utils/routeHelper';

import { Header } from 'components/layouts';
import { Col, LoadingSpinner, Fab } from 'components/widgets';

import './MyLoans.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux-hooks';

const MyLoans = () => {
  useLoanAndScheduledPaymentFetcher();
  const { isCreatingApplicationUrl, createApplicationUrl } = useApplicationUrlCreator();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loans = useSelector(loansSelector);
  const isFetching = useSelector(isFetchingOrSynchingSelector);

  const handleOnClickLoanItem = (loanId: string) => {
    dispatch(setSelectedLoanId(loanId));
    localStorage.setItem('selectedLoanId', loanId);
    navigate(routes.LOAN_OVERVIEW);
  };

  const handleApplyLoan = () => createApplicationUrl();

  return (
    <Col xs={12} sm={6} md={6} styleName="wrapper">
      <Header />
      {isFetching && <LoadingSpinner />}
      <List styleName="list loans">
        {loans.map((loan) => (
          <ListItem button key={loan.id} styleName="list-item loan" onClick={() => handleOnClickLoanItem(loan.id)}>
            <div styleName="loan-number">{loan.id}</div>
          </ListItem>
        ))}
      </List>
      <Fab onClick={handleApplyLoan} disabled={isCreatingApplicationUrl} width="80%">
        {isCreatingApplicationUrl ? <LoadingSpinner styleName="custom loading apply-loan" /> : 'Apply for new plan'}
      </Fab>
    </Col>
  );
};

export default MyLoans;
