import { FormEvent, useEffect, useState } from 'react';
import { isEmpty, isNil } from 'lodash';

import { AREA_CODE_US_CANADA, isCordovaIOSApp } from 'utils/constants';
import { makeRawPhoneNumber } from 'utils/format';
import { routes } from 'utils/routeHelper';

import { Col, Fab, PhoneNumberInput, KeyboardWrapper } from 'components/widgets';
import { IconScratchpayHeart } from 'assets/icons';
import { LogoScratchpayTextOnlyWhite } from 'assets/images';

import './Login.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { initializeATT } from 'utils/appleTracking';
import { addOsanoWidget } from '../../utils/osano.ts';
import { removeMavenWidget } from '../../utils/maven.ts';

const RAW_PHONE_NUMBER_LENGTH = 10;
const ERROR_MESSAGES = {
  TOO_LONG_PHONE_NUMBER: 'This number seems too long, only US and Canada phone number are supported yet.',
};

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  removeMavenWidget();

  const [areaCode, setAreaCode] = useState<string>(AREA_CODE_US_CANADA);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const rawPhoneNumber = makeRawPhoneNumber(phoneNumber);
  const isSendOTPButtonDisabled =
    isEmpty(areaCode) || isEmpty(phoneNumber) || rawPhoneNumber.length < RAW_PHONE_NUMBER_LENGTH;

  useEffect(() => {
    addOsanoWidget(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (location.state && location.state.areaCode && location.state.phoneNumber) {
      setAreaCode(location.state.areaCode);
      setPhoneNumber(location.state.phoneNumber);
    }
    if (isCordovaIOSApp) {
      initializeATT();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChangePhoneNumber = (updatedPhoneNumber: string) => {
    if (updatedPhoneNumber === '(') {
      setPhoneNumber('');
    } else {
      setPhoneNumber(updatedPhoneNumber);
    }
    setErrorMessage(null);
  };

  const handleOnFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleOnSendOTP();
  };

  const handleOnSendOTP = () => {
    if (isSendOTPButtonDisabled) {
      return;
    }
    if (rawPhoneNumber.length > RAW_PHONE_NUMBER_LENGTH) {
      setErrorMessage(ERROR_MESSAGES.TOO_LONG_PHONE_NUMBER);
      return;
    }
    navigate(routes.OTP_CONFIRMATION, {
      state: {
        phoneNumber,
        areaCode,
      },
    });
  };

  return (
    <div styleName="wrapper">
      <div styleName="wrapper-logo">
        <IconScratchpayHeart styleName="icon heart" />
        <img src={LogoScratchpayTextOnlyWhite} alt="logo-scratchpay" styleName="logo" />
      </div>
      <Col xs={12} sm={6} md={6}>
        <KeyboardWrapper>
          <form autoComplete="on" onSubmit={handleOnFormSubmit}>
            <PhoneNumberInput
              label="Phone number"
              value={phoneNumber}
              areaCode={areaCode}
              error={!isNil(errorMessage)}
              errorMessage={errorMessage || ''}
              onChange={handleOnChangePhoneNumber}
            />
          </form>
          <p styleName="disclaimer">
            Enter your Phone number to receive confirmation code & log in to your Scratchpay account securely
          </p>
          <Fab
            disabled={isSendOTPButtonDisabled}
            onClick={handleOnSendOTP}
            AboveComponent={
              <Col xs={12} sm={6} md={6}>
                <div styleName="disclaimer text-above-fab" className="above-fab">
                  By tapping "send code", you authorize Scratchpay to send you a verification code via SMS message or
                  prerecorded or artificial voice message that you can use to log into your account. Message and data
                  rates may apply. Your number will be sent to & stored by Google to improve spam & abuse prevention
                  across Google services, including but not limited to Firebase.
                </div>
              </Col>
            }
          >
            Send code
          </Fab>
        </KeyboardWrapper>
      </Col>
    </div>
  );
};

export default Login;
